import { ChevronLeft, Group, Help, History, InsertChart, Layers, LibraryBooks, Menu as MenuIcon, Search, Settings, Tune } from '@mui/icons-material';
import { Autocomplete, Box, Divider, Drawer, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Stack, styled, TextField, Toolbar, Typography } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { version } from '../..';
import { ApplicationContext, IApplicationContext, IUserContext, UserContext } from '../../App';
import { ADMIN_ROLE, AGENT_ROLE, ANALYST_ROLE, CURATOR_ROLE, DATA_ROLE, DOCTOR_ROLE, SAFETY_ROLE, SUPER_ADMIN_ROLE } from '../../const/roles';
import ResearchDTO from '../../dto/ResearchDTO';
import { getResearchesByUser } from '../../queries/Researches';
import { getHelp } from '../../queries/Users';
import { useRole } from '../../util/useRole';
import IPersistentDrawerProps from './IPersistentDrawerProps';
import { appbarHeight, drawerWidth } from './Layout';
import NotificationsMenu from './NotificationsMenu';
import UserMenu from './UserMenu';

export const contentWidth = 1100;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<{
    open: boolean;
}>(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));


interface IMainComponentProps {
    open: boolean;
    withDrawer: boolean;
}


const Main = styled('main', {
    shouldForwardProp: (prop) => prop !== 'open' && prop !== 'withDrawer'
})<IMainComponentProps>(({ theme, open, withDrawer }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    paddingTop: theme.spacing(2),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: withDrawer ? 0 : `${drawerWidth}px`,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: drawerWidth,
    })
}),
);


const Content = styled('div')({
    marginLeft: 'auto',
    marginRight: 'auto',
    '@media (min-width: 1024px)': {
        width: contentWidth
    }
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    height: appbarHeight,
    ...theme.mixins.toolbar,
    justifyContent: 'space-between',
    padding: '0 8px',
}));

const PersistentDrawer = (props: IPersistentDrawerProps) => {
    const [open, setOpen] = useState<boolean>(localStorage.getItem('drawerOpen') !== "false");
    const [selectResearch, setSetelectResearch] = useState<ResearchDTO | null>(null);

    const [title, setTitle] = useState<string>("Управление исследованиями");
    const location = useLocation();

    const usrCtx = useContext<IUserContext | null>(UserContext);
    const appCtx = useContext<IApplicationContext | null>(ApplicationContext);

    const navigate = useNavigate();
    const role = useRole();

    const getResearchesByUserQuery = useQuery(["Researches", "ForUser", usrCtx?.user?.id], getResearchesByUser, {
        enabled: !!usrCtx?.user?.id
    });

    useEffect(() => {
        if (!role) {
            setTitle('Вход');
            return;
        }

        if (!document) {
            setTitle('Загрузка');
            return;
        }

        let newTitle = "Управление исследованиями";

        if (location.pathname.includes('/access_forbidden')) {
            newTitle = 'Доcтуп запрещен';
        } else if (location.pathname.includes('/accident')) {
            if (location.pathname.includes('/add')) {
                if (role !== DOCTOR_ROLE) {
                    navigate("/access_forbidden");
                }
                newTitle = 'Нежелательное явление';
            } else if (location.pathname.includes('/edit')) {
                if (role !== DOCTOR_ROLE) {
                    navigate("/access_forbidden");
                }
                newTitle = 'Редактирование нежелательного явления';
            } else {
                if (role === ADMIN_ROLE || role === SUPER_ADMIN_ROLE || role === ANALYST_ROLE) {
                    navigate("/access_forbidden");
                }
                newTitle = 'Просмотр формы нежелательного явления';
            }
        } else if (location.pathname.includes('/documentTypes')) {
            if (role !== ADMIN_ROLE && role !== SUPER_ADMIN_ROLE) {
                navigate("/access_forbidden");
            }
            newTitle = 'Управление анкетами';
        } else if (location.pathname.includes('/documentScans')) {
            if (role !== ADMIN_ROLE && role !== SUPER_ADMIN_ROLE) {
                navigate("/access_forbidden");
            }
            newTitle = 'Управление документами';
        } else if (location.pathname.includes('/document/add')) {
            if (role !== DOCTOR_ROLE) {
                navigate("/access_forbidden");
            }
            newTitle = 'Работа с анкетой';
        } else if (location.pathname.includes('/document/edit')) {
            if (role !== DOCTOR_ROLE && role !== DATA_ROLE) {
                navigate("/access_forbidden");
            }
            newTitle = 'Работа с анкетой';
        } else if (location.pathname.includes('/document/view')) {
            newTitle = 'Просмотр документа';
        } else if (location.pathname.includes('/researchview')) {
            if (role === ADMIN_ROLE || role === SUPER_ADMIN_ROLE || role === ANALYST_ROLE) {
                navigate("/access_forbidden");
            } else if ((role === DOCTOR_ROLE || role === AGENT_ROLE) && getResearchesByUserQuery.data) {
                const research = getResearchesByUserQuery.data.find(r => r.id.toString() === location.pathname.split("/")[2]);
                if (!research) {
                    navigate("/access_forbidden");
                }
            }
            newTitle = 'Панель исследования';
        } else if (location.pathname.includes('/patient')) {
            if (role !== DOCTOR_ROLE) {
                navigate("/access_forbidden");
            }
            newTitle = 'Пациент';
        } else if (location.pathname.includes('/users')) {
            if (role !== ADMIN_ROLE && role !== SUPER_ADMIN_ROLE) {
                navigate("/access_forbidden");
            }
            newTitle = 'Управление пользователями';
        } else if (location.pathname.includes('/user')) {
            if (role !== ADMIN_ROLE && role !== SUPER_ADMIN_ROLE) {
                navigate("/access_forbidden");
            }
            newTitle = 'Пользователь';
        } else if (location.pathname.includes('/researchdownload')) {
            if (role !== ANALYST_ROLE && role !== CURATOR_ROLE) {
                navigate("/access_forbidden");
            }
            newTitle = 'Выгрузка данных по исследованию';
        } else if (location.pathname.includes('/research')) {
            if (role !== ADMIN_ROLE && role !== SUPER_ADMIN_ROLE) {
                navigate("/access_forbidden");
            }
            newTitle = 'Исследование';
        } else if (location.pathname.includes('/charts')) {
            if (role !== ANALYST_ROLE && role !== CURATOR_ROLE) {
                navigate("/access_forbidden");
            }
            newTitle = 'Визуальная аналитика';
        } else if (location.pathname.includes('/settings')) {
            if (role !== ADMIN_ROLE && role !== SUPER_ADMIN_ROLE) {
                navigate("/access_forbidden");
            }
            newTitle = 'Базовые настройки';
        } else if (location.pathname.includes('/logs')) {
            if (role !== ADMIN_ROLE && role !== SUPER_ADMIN_ROLE) {
                navigate("/access_forbidden");
            }
            newTitle = 'Просмотр логов';
        } else if (location.pathname.includes('/profile')) {
            if (![ADMIN_ROLE, SUPER_ADMIN_ROLE, DOCTOR_ROLE, CURATOR_ROLE].includes(role ?? "")) {
                navigate("/access_forbidden");
            }
            newTitle = 'Личный кабинет';
        } else {
            newTitle = role === ADMIN_ROLE || role === SUPER_ADMIN_ROLE ?
                "Управление исследованиями" :
                'Все исследования';
        }
        let docTitle = newTitle + ' — Medaction';
        document.title = docTitle;
        setTitle(newTitle);
    }, [location, role, navigate, getResearchesByUserQuery]);

    const getHelpQuery = useMutation(getHelp, {
        onMutate: () => {
            appCtx?.setLoading(true);
        },
        onSettled: () => {
            appCtx?.setLoading(false);
        },
        onSuccess: (file) => {
            const url = URL.createObjectURL(file);
            const element = document.createElement('a');
            if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
                (window.navigator as any).msSaveOrOpenBlob(file, file.name);
            } else {
                element.setAttribute('href', url);
                element.setAttribute('download', file.name)
                element.style.display = 'none';
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);
            }
        }
    });

    const handleDrawerOpen = useCallback(() => {
        localStorage.setItem('drawerOpen', 'true');
        setOpen(true);
        appCtx?.setDrawerOpen(true);
    }, [appCtx]);

    const handleDrawerClose = useCallback(() => {
        localStorage.setItem('drawerOpen', 'false');
        setOpen(false);
        appCtx?.setDrawerOpen(false);
    }, [appCtx]);

    const drawerOpen = useMemo(() => {
        return usrCtx?.user !== null && open;
    }, [open, usrCtx?.user]);

    const handleResearchClick = useCallback((event: any, newValue: ResearchDTO | null) => {
        setSetelectResearch(newValue);
        navigate(newValue ? `/researchview/${newValue?.id}` : `/`);
    }, [navigate]);

    const handleHelpClick = useCallback(() => {
        getHelpQuery.mutate();
    }, [getHelpQuery]);

    return (
        <>
            {
                !props.hideDrawer &&
                <Drawer
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                        },
                    }}
                    variant="persistent"
                    anchor="left"
                    open={drawerOpen}
                >
                    <DrawerHeader>
                        <Stack
                            display='flex'
                            alignItems="center"
                            flexDirection='row'
                        >
                            <img
                                alt='System Logo'
                                src='/logo.svg'
                                width={80}
                            />
                            <Typography
                                sx={{
                                    fontSize: "1.3rem",
                                    fontWeight: "500",
                                    color: "inherit",
                                    marginLeft: "8px"
                                }}
                                variant='subtitle1'
                            >
                                Medaction
                            </Typography>
                        </Stack>
                        <IconButton onClick={handleDrawerClose}>
                            <ChevronLeft />
                        </IconButton>
                    </DrawerHeader>
                    <Divider />
                    <List
                        disablePadding={true}
                    >
                        {
                            (role === ADMIN_ROLE || role === SUPER_ADMIN_ROLE) &&
                            <ListItemButton
                                onClick={() => navigate('/documentTypes')}
                            >
                                <ListItemIcon>
                                    <Tune />
                                </ListItemIcon>
                                <ListItemText
                                    primary='Конструктор'
                                />
                            </ListItemButton>
                        }
                        {
                            (role === ADMIN_ROLE || role === SUPER_ADMIN_ROLE) &&
                            <ListItemButton
                                onClick={() => navigate('/documentScans')}
                            >
                                <ListItemIcon>
                                    <LibraryBooks />
                                </ListItemIcon>
                                <ListItemText
                                    primary='Документы'
                                />
                            </ListItemButton>
                        }
                        <ListItemButton
                            onClick={() => navigate('/')}
                        >
                            <ListItemIcon>
                                <Layers />
                            </ListItemIcon>
                            <ListItemText
                                primary='Исследования'
                            />
                        </ListItemButton>
                        {
                            (role === ADMIN_ROLE || role === SUPER_ADMIN_ROLE) &&
                            <Divider />
                        }
                        {
                            (role === ADMIN_ROLE || role === SUPER_ADMIN_ROLE) &&
                            <ListItemButton
                                onClick={() => navigate('/users')}
                            >
                                <ListItemIcon>
                                    <Group />
                                </ListItemIcon>
                                <ListItemText
                                    primary='Пользователи'
                                />
                            </ListItemButton>
                        }
                        {
                            (role === ADMIN_ROLE || role === SUPER_ADMIN_ROLE) &&
                            <ListItemButton
                                onClick={() => navigate('/logs')}
                            >
                                <ListItemIcon>
                                    <History />
                                </ListItemIcon>
                                <ListItemText
                                    primary='Логирование'
                                />
                            </ListItemButton>
                        }
                        {
                            (role === ADMIN_ROLE || role === SUPER_ADMIN_ROLE) &&
                            <ListItemButton
                                onClick={() => navigate('/settings')}
                            >
                                <ListItemIcon>
                                    <Settings />
                                </ListItemIcon>
                                <ListItemText
                                    primary='Настройки'
                                />
                            </ListItemButton>
                        }
                        {
                            (role === CURATOR_ROLE || role === ANALYST_ROLE) &&
                            <ListItemButton
                                onClick={() => navigate('/charts')}
                            >
                                <ListItemIcon>
                                    <InsertChart />
                                </ListItemIcon>
                                <ListItemText
                                    primary='Визуализация'
                                />
                            </ListItemButton>
                        }
                        </List>
                        <Box
                            sx={{
                                marginTop: 'auto',
                                textAlign: 'center',
                                color: 'gray'
                            }}
                        >
                            <Typography>
                                {version}
                            </Typography>
                        </Box>
                </Drawer>
            }
            {
                !props.hideDrawer &&
                <AppBar
                    position='fixed'
                    open={open}
                    sx={{
                        height: appbarHeight
                    }}
                >
                    <Toolbar>
                        {
                            !open ?
                                <IconButton
                                    color='secondary'
                                    aria-label="open drawer"
                                    onClick={handleDrawerOpen}
                                    edge="start"
                                    sx={{
                                        marginLeft: '-12px',
                                        marginRight: '20px',
                                        color: 'white'
                                    }}
                                >
                                    <MenuIcon />
                                </IconButton> :
                                null
                        }
                        <Typography
                            color='white'
                            variant="h5"
                            noWrap
                            component="div"
                            sx={{ flexGrow: 1 }}
                        >
                            {title}
                        </Typography>
                        {
                            (
                                usrCtx?.user &&
                                role !== ADMIN_ROLE &&
                                role !== ANALYST_ROLE &&
                                role !== SUPER_ADMIN_ROLE &&
                                role !== SAFETY_ROLE
                            ) &&
                            <Box
                                display='flex'
                                alignItems='center'
                                mr={2}
                            >
                                <Autocomplete
                                    id="select-research"
                                    value={selectResearch}
                                    onChange={handleResearchClick}
                                    options={getResearchesByUserQuery.data ?? []}
                                    getOptionLabel={(option: ResearchDTO) => option.shortName}
                                    noOptionsText='Нет результатов'
                                    sx={{
                                        width: '400px',
                                        "& .MuiAutocomplete-clearIndicator": {
                                            color: "white"
                                        },
                                        "& .MuiAutocomplete-popupIndicator": {
                                            color: "white"
                                        }
                                    }}
                                    renderInput={(params) =>
                                        <Box
                                            display='flex'
                                            alignItems='flex-end'
                                        >
                                            <Search sx={{ mr: 2 }} />
                                            <TextField
                                                {...params}
                                                focused
                                                sx={{
                                                    input: { color: 'white' },
                                                }}
                                                color='info'
                                                variant="standard"
                                                fullWidth
                                                placeholder="Поиск исследования"
                                            />
                                        </Box>
                                    }
                                />
                            </Box>
                        }
                        <IconButton
                            onClick={handleHelpClick}
                            color='inherit'
                        >
                            <Help
                                sx={{
                                    fontSize: 30
                                }}
                            />
                        </IconButton>
                        <NotificationsMenu />
                        <UserMenu />
                    </Toolbar>
                </AppBar>
            }
            <Main
                open={!props.hideDrawer && open}
                withDrawer={!props.hideDrawer}
            >
                {
                    !props.hideDrawer &&
                    <DrawerHeader />
                }
                <Content>
                    {props.children}
                </Content>
            </Main>
        </>
    );
}

export default PersistentDrawer;
