import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import 'moment/locale/ru';
import React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { BrowserRouter } from 'react-router-dom';
import 'typeface-roboto';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';

export const version = "2.4.13";

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') as string;

axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("accessToken");

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const tagManagerArgs = {
    gtmId: 'GTM-TP7WSX5'
}

TagManager.initialize(tagManagerArgs)

const theme = createTheme({
    components: {
        MuiDialogContentText: {
            styleOverrides: {
                root: {
                    fontSize: "1rem"
                }
            }
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    fontSize: "1.3125rem",
                    fontWeight: 500
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                h6: {
                    fontSize: '1rem',
                    lineHeight: 1.5
                },
                h5: {
                    fontSize: '1.3125rem',
                    lineHeight: "1.16667em"
                },
                h4: {
                    fontSize: '1.5rem',
                    lineHeight: "1.35417em"
                },
                subtitle2: {
                    fontSize: '0.875rem',
                    lineHeight: "1.71429em",
                    color: "grey"
                },
                body1: {
                    fontSize: "0.8125rem",
                    lineHeight: "1.46429em"
                }
            }
        },
        MuiListItemText: {
            styleOverrides: {
                root: {
                    "&>span": {
                        fontSize: "1rem"
                    }
                }
            }
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    height: 56
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    padding: '4px 24px 4px 24px',
                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
                },
                head: {
                    fontSize: "0.75rem !important",
                    color: 'grey !important',
                    fontWeight: "500 !important"
                },
                body: {
                    fontSize: "0.8125rem !important",
                    height: 48
                }
            }
        },
        MuiTablePagination: {
            styleOverrides: {
                root: {
                    fontSize: "0.75rem !important",
                    color: 'grey !important',
                    fontWeight: "500 !important"
                },
                selectLabel: {
                    fontSize: "0.75rem !important",
                    color: 'grey !important',
                    fontWeight: "500 !important"
                },
                displayedRows: {
                    fontSize: "0.75rem !important",
                    color: 'grey !important',
                    fontWeight: "500 !important"
                },
                selectIcon: {
                    top: "calc(50% - .6em)"
                }
            }
        }
    },
    palette: {
        background: {
            default: '#f1f1f1'
        },
        primary: {
            main: '#24226a',
            light: '#d4def2',
            dark: '#323c8e',
            contrastText: '#ffffff'
        },
        secondary: {
            main: '#323c8e',
        },
        text: {
            secondary: 'grey'
        },
        info: {
            main: '#ffffff',
        },
        error: {
            main: '#f55b41',
        },
        success: {
            main: '#91c461'
        }
    }
});

root.render(
    <React.StrictMode>
        <BrowserRouter basename={baseUrl}>
            <ThemeProvider theme={theme}>
                <App />
            </ThemeProvider>
        </BrowserRouter>
    </React.StrictMode>
);

reportWebVitals();
